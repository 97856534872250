import { Component, Inject } from "@angular/core"
import { MAT_SNACK_BAR_DATA } from "@angular/material/snack-bar"

@Component({
    selector: 'insc-success-snackbar',
    templateUrl: './success-snackbar.component.html',
    styleUrls: ['../snackbars.scss'],
    styles: [`.icon {
    color: #4BB543;
  }`],
    standalone: false
})
export class SuccessSnackbarComponent {

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: {message: string}
  ) { }

}

