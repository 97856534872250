import { AfterContentInit, Component, ComponentFactoryResolver, Inject, Type, ViewChild } from "@angular/core"
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar"
import {
  APIError,
  DatabaseError,
  DeletePreventedByAssociationsError,
  LinkingError,
  NoPermissionError,
  NotAuthorizedError,
  UploadError,
  ValidationError
} from "../../../services/errors"


import { ErrorViewHostDirective } from "./error-view-host.directive"
import { DatabaseErrorViewComponent } from "./error-views/database-error-view.component"
import { DeletePreventedByAssociationErrorViewComponent } from "./error-views/delete-prevented-by-association-error-view.component"
import { ErrorViewBasicComponent } from "./error-views/error-view-basic.component"

@Component({
    selector: 'insc-error-snackbar',
    templateUrl: './error-snackbar.component.html',
    styleUrls: ['../snackbars.scss', 'error-snackbar.component.scss'],
    styles: [`.icon {
    color: #cc0000;
  }`],
    standalone: false
})
export class ErrorSnackbarComponent implements AfterContentInit {

  @ViewChild(ErrorViewHostDirective, { static: true }) errorViewHost: ErrorViewHostDirective

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: {error: APIError},
    public snackBarRef: MatSnackBarRef<ErrorSnackbarComponent>,
    private componentFactoryResolver: ComponentFactoryResolver
  ) { }

  ngAfterContentInit() {
    const errorViewComponentType = this.resolveErrorViewComponent(this.data.error)
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(errorViewComponentType.componentType)
    const componentRef = this.errorViewHost.viewContainerRef.createComponent(componentFactory)

    errorViewComponentType.initFn(componentRef.instance)
  }

  private resolveErrorViewComponent(error: APIError): { componentType: Type<unknown>; initFn: (view: unknown) => void} {

    if (!error) {
      return {
        componentType: ErrorViewBasicComponent,
        initFn:        (view: ErrorViewBasicComponent) => {
          view.message = "Unbekannter Serverfehler"
        }
      }
    }

    switch (error.constructor) {
      case DeletePreventedByAssociationsError: return {
        componentType: DeletePreventedByAssociationErrorViewComponent,
        initFn:        (view: DeletePreventedByAssociationErrorViewComponent) => {
          view.error = <DeletePreventedByAssociationsError>error
        }
      }

      case DatabaseError: return {
        componentType: DatabaseErrorViewComponent,
        initFn:        (view: DatabaseErrorViewComponent) => {
          const databaseError = <DatabaseError>error
          view.type = databaseError.type
          view.message = databaseError.message
        }
      }

      case UploadError: return {
        componentType: ErrorViewBasicComponent,
        initFn:        (view: ErrorViewBasicComponent) => {
          view.message = `Fehler beim Bildupload: ${(<UploadError>error).message} Siehe Uploadprotokoll für Details.`
        }
      }

      case ValidationError: return {
        componentType: ErrorViewBasicComponent,
        initFn:        (view: ErrorViewBasicComponent) => {
          view.message = "Formulardaten ungültig"
        }
      }

      case NotAuthorizedError:
      case NoPermissionError: return {
        componentType: ErrorViewBasicComponent,
        initFn:        (view: ErrorViewBasicComponent) => {
          view.message = "Keine Berechtigung"
        }
      }

      case LinkingError: return {
        componentType: ErrorViewBasicComponent,
        initFn:        (view: ErrorViewBasicComponent) => {
          const linkingError = <LinkingError>error
          view.message = `Verknüpfungsfehler: ${linkingError.message}`
        }
      }

      default: return {
        componentType: ErrorViewBasicComponent,
        initFn:        (view: ErrorViewBasicComponent) => {
          view.message = "Unbekannter Fehler"
        }
      }
    }
  }



}
